import { defineNuxtPlugin, useRuntimeConfig, useCookie } from 'nuxt/app'
import { type TRPCLink, TRPCClientError, loggerLink } from '@trpc/client'
// import { observable } from '@trpc/server/observable'
import superjson from 'superjson'
// import { createTRPCNuxtClient, httpBatchLink } from '../trpc-nuxt/client'
import { createTRPCNuxtClient, httpBatchLink } from 'trpc-nuxt/client'

import type { trpcAppRouterType } from '../server/trpc/routers'

/**
 * isTRPCClientError function
 * see https://trpc.io/docs/client/vanilla/infer-types#infer-trpcclienterror-types
 */
export function isTRPCClientError(cause: unknown): cause is TRPCClientError<trpcAppRouterType> {
  return cause instanceof TRPCClientError
}

/**
 * link function to renew token before its expiration date
 * see https://trpc.io/docs/links
 */

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const trpc = createTRPCNuxtClient<trpcAppRouterType>({
    links: [
      /**
       * The function passed to enabled is an example in case you want to the link to
       * log to your console in development and only log errors in production
       */
      // loggerLink({
      //   enabled: (opts) =>
      //     (config.public.env.isDevelopment && typeof window !== 'undefined') ||
      //     (opts.direction === 'down' && opts.result instanceof Error),
      // }),
      httpBatchLink({
        url: config.public.trpcApi,
        transformer: superjson, // moved here for proper configuration
      }),
    ],
  })

  // Providing the trpc
  return {
    provide: {
      trpc,
    },
  }

  // we can not use the Nuxt standard provide way which is loosing typings :
  // nuxtApp.provide('trpc', trpc) // not working
})
