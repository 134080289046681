<script setup lang="ts">
import { useDisplay, useTheme } from 'vuetify'
import { hexToRgb } from '~~/utils'

const { setAppDir, isAppRtl, syncVuetifyThemeWithTheme } = useThemeConfig()

const vuetifyTheme = useTheme()
const currentThemeName = vuetifyTheme.name.value

// set initial primary color
const primaryColor = ref(
  vuetifyTheme.themes.value[currentThemeName as 'light' | 'dark'].colors.primary,
)

syncVuetifyThemeWithTheme()

const { width } = useDisplay()

onMounted(async () => {
  // onMounted or onUpdated will NOT be called during SSR and will only be executed on the client
  setAppDir(isAppRtl.value ? 'rtl' : 'ltr')
})
</script>

<template>
  <!-- <CoreLoaderAppStarter /> -->

  <VLocaleProvider v-show="width" :rtl="isAppRtl">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp
      :style="`--v-global-theme-primary: ${hexToRgb(
        vuetifyTheme.global.current.value.colors.primary,
      )}`"
    >
      <NuxtLoadingIndicator :color="primaryColor" />
      <!-- <CoreLoaderOverlayDottedCircle nuxthook /> -->
      <NuxtLayout />
    </VApp>
  </VLocaleProvider>
</template>
