import type {
  RouteLocationNamedI18n,
  // RouteLocationRawI18n,
  // RouteLocation,
  // RouteLocationRaw,
} from 'vue-router'
import type { Locale } from 'vue-i18n'

/* Types
<NuxtLink :to="RouteLocationRaw" />


const localePath = useLocalePath()
localePath(route: RouteLocation | RouteLocationRaw, locale?: Locale) => string

const localeRoute()
localeRoute(route: RouteLocationRaw, locale?: Locale) => Route | (RouteLocation & { href: string }) | undefined

RouteLocation :
{
  name: string | symbol
  path: string
  hash: string
  query: string
  params: string
  fullPath: string
  matched: RouteRecordNormalized[]
  redirectedFrom?: RouteLocationNormalized
}

RouteLocationRaw : string | RouteLocationPathRaw | RouteLocationNamedRaw

RouteLocationPathRaw extends RouteQueryAndHash, MatcherLocationAsPath, RouteLocationOptions
RouteLocationNamedRaw extends RouteQueryAndHash, LocationAsRelativeRaw, RouteLocationOptions

MatcherLocationAsPath {
    path: string;
}
LocationAsRelativeRaw {
    name?: RouteRecordName;
    path?: undefined;
    params?: RouteParamsRaw;
}
*/

export function getNoLocalizedPath(path: string, locale?: Locale): string {
  if (!locale || locale === 'en') return path
  const pathParts = path.split('/')
  return '/' + pathParts.slice(2).join('/')
}

export function localeLink(route: RouteLocationNamedI18n, loc?: Locale): string {
  const localePath = useLocalePath()
  const r = localePath(route, loc)
  if (r) return r
  // return a nuxt content path
  const { locale } = useNuxtApp().$i18n // recommended workaround useNuxtApp().$i18n when const { t } = useI18n() not working in composables
  let locPrefix = ''
  if (loc && loc !== 'en') locPrefix = `/${loc}`
  else if (locale.value !== 'en') locPrefix = `/${locale.value}`
  return `${locPrefix}/${route.toString()}`
}

/*
export function localeLink(
  route: RouteLocation | RouteLocationRaw,
  locale?: Locale,
): string {
  const localePath = useNuxtApp().$localePath
  if (typeof route === 'string') {
    if (route.includes('?')) {
      // manage case where route is a string with query params
      const path = route.split('?')[0]
      const params = new URLSearchParams(route.split('?')[1])
      return localePath(path, (locale = undefined)) + '?' + params.toString()
    } else {
      return localePath(route, (locale = undefined))
    }
  }
  return localePath(route, (locale = undefined))
}
*/
