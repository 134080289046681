import { loadStripe, type Stripe, type StripeEmbeddedCheckout } from '@stripe/stripe-js'
import { sleep } from 'dvn-js-utils'
import type { CartProduct, SessionStatus } from '~~/types'
import { useNuxtApp, defineStore, useRuntimeConfig, useCart, useUserStore } from '#imports'

export const useStripe = defineStore('stripe', () => {
  const { $trpc } = useNuxtApp()
  const STRIPE_PUBLIC_KEY = useRuntimeConfig().public.stripe.publicApiKey

  let stripe: Stripe | null = null
  let embeddedCheckout: StripeEmbeddedCheckout | undefined = undefined

  async function loadStripeScript() {
    if (!stripe) {
      stripe = await loadStripe(STRIPE_PUBLIC_KEY)
    }
  }

  // connected accounts
  async function getAccounts() {
    return await $trpc.str_Stripe.listAccounts.query()
  }

  async function createConnectedAccount(email: string, userId: string) {
    return await $trpc.str_Stripe.createAccount.mutate({ email, userId })
  }

  async function getAccountLinkUrl(accountId: string) {
    return await $trpc.str_Stripe.getAccountLinkUrl.query({ accountId })
  }

  async function getExpressDashboardLoginLinkUrl(accountId: string) {
    return await $trpc.str_Stripe.getExpressDashboardLoginLinkUrl.query({ accountId })
  }

  async function getAccountDetail(accountId: string) {
    return await $trpc.str_Stripe.getAccountDetail.query({ accountId })
  }

  async function getPlatformCurrency() {
    return await $trpc.str_Stripe.getPlatformCurrency.query()
  }

  async function getPlatformFee() {
    return await $trpc.str_Stripe.getPlatformFee.query()
  }

  async function getMinimumTransferAmount() {
    return await $trpc.str_Stripe.getMinimumTransferAmount.query()
  }

  async function syncAccount() {
    return await $trpc.str_Stripe.syncAccount.mutate()
  }

  async function deleteAccount(accountId: string) {
    return await $trpc.str_Stripe.deleteAccount.mutate({ accountId })
  }

  // dashboard url
  async function getDashboardUrl() {
    const userStore = useUserStore()
    if (
      userStore.user?.stripeAccountCompleted &&
      userStore.user?.stripeAccountId &&
      userStore.user?.stripeAccountType === 'express'
    )
      return await getExpressDashboardLoginLinkUrl(userStore.user.stripeAccountId)
    if (
      userStore.user?.stripeAccountCompleted &&
      userStore.user?.stripeAccountId &&
      userStore.user?.stripeAccountType === 'standard'
    )
      return 'https://dashboard.stripe.com/dashboard'
  }

  // checkout session

  async function createCheckoutSession(products: CartProduct[]) {
    const _products = products.map((e) => e._id)
    const sessionSecret = await $trpc.str_Stripe.createCheckoutSession.mutate({
      products: _products,
    })
    return sessionSecret
  }

  async function checkoutPayment() {
    const cart = useCart()
    const sessionSecret = await createCheckoutSession(cart.products)
    return sessionSecret
  }

  async function getCheckoutSessionStatus(sessionId: string): Promise<SessionStatus> {
    return await $trpc.str_Stripe.getCheckoutSessionStatus.query({ sessionId })
  }

  // payment

  async function displayEmbeddedCheckout(): Promise<boolean> {
    // https://docs.stripe.com/checkout/embedded/quickstart?lang=node
    await loadStripeScript()
    if (!stripe) throw new Error('Failed to load Stripe')
    embeddedCheckout = await stripe.initEmbeddedCheckout({ fetchClientSecret: checkoutPayment })
    embeddedCheckout.mount('#checkout')
    await sleep(1600) // wait 1500ms for iframe load to avoid white loading screen
    return true
  }

  function destroyEmbeddedCheckout() {
    if (embeddedCheckout) embeddedCheckout.destroy()
  }

  // catalog

  async function getProducts() {
    return await $trpc.str_Stripe.listProducts.query()
  }

  async function getPrices(productId: string) {
    return await $trpc.str_Stripe.listPrices.query()
  }

  return {
    // Stripe API
    loadStripeScript,

    // connected accounts
    getAccounts,
    createConnectedAccount,
    getAccountLinkUrl,
    getExpressDashboardLoginLinkUrl,
    getAccountDetail,
    getPlatformCurrency,
    getPlatformFee,
    getMinimumTransferAmount,
    syncAccount,
    deleteAccount,

    // checkout session
    embeddedCheckout,
    createCheckoutSession,
    checkoutPayment,
    getCheckoutSessionStatus,

    // payment
    displayEmbeddedCheckout,
    destroyEmbeddedCheckout,
    getDashboardUrl,

    // catalog
    getProducts,
    getPrices,
  }
})
