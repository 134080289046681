import routerOptions0 from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.16.2_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@types+node@22.13.17_bett_52cbac6499406c8c77c1f0f776dfca75/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}