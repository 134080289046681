
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _404qZ_45AMU2uoSibXQLbHxtuaHnjf97boB7aIHnQWRpEHy0Meta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/404.vue?macro=true";
import { default as helpDi51Wy6WyR73qNNgzbGa2_xONbteFSboUU6dPVyk_45_45kMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/help.vue?macro=true";
import { default as indexPlB30iT_45EKa6UklGUHjuTXbhtAo1UKEDE_45pbXvTnzeoMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/index.vue?macro=true";
import { default as loginLMbKxyL3S6ISbcaPjbwLDdEsrQBXATHxQIcRnt_45skDQMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/login.vue?macro=true";
import { default as register_KfaqY1MGV3NlCEQfwlNnlE_gqtHhp9VplXLykyR6LUMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/register.vue?macro=true";
import { default as _91_46_46_46slug_93Gfcxej8es09s47M_D1ADbXDOGrVLmiPBWDQ1Tvj_PCAMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/[...slug].vue?macro=true";
import { default as about_45yiRsyW_P4NQ5hC_45Jej1GsrSFzZinawbZ9j19lvSAvYMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/about.vue?macro=true";
import { default as contact3f1fkihGlAnbdR0WfNuJ97HQov0yQ3E5Zt_459r59h1g8Meta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/contact.vue?macro=true";
import { default as indexi1_45nTtBWOORWno6Cz_5V96z__rEXrdMsdI6d5N1qAqQMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/index.vue?macro=true";
import { default as not_45authorizedM1uq_45dW_c6_45S6KU1SjNcqGryYz4r6bE85eG8C1DH2EcMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/not-authorized.vue?macro=true";
import { default as carttTXpNu1zDHfeorTzHiiaQYkVJ3bOCPn94YXJCg_HQ0kMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/shop/cart.vue?macro=true";
import { default as forgot_45passwordSD4wf64ZLigBabaLOgBazequymbvT3exzu_45YejdJYOMMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/forgot-password.vue?macro=true";
import { default as cartrxxyEWBJHB60ZLzmrxK9SYfImdej9P_S3BLBhB96ng4Meta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/cart.vue?macro=true";
import { default as tagsmrcws0EsF68Ofzte5RfIzifUt6eIshJWixeg9hsE5oEMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/tags.vue?macro=true";
import { default as sales8aB03YUh4pzlRzP_45K99uoE1INiGXJ3u6Pr955YPTMnQMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/sales.vue?macro=true";
import { default as packspPlQg6xxM64ucHnAYIz8Q7CsWGrKQlZ_DN_45MYHLmNmIMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/packs.vue?macro=true";
import { default as users0mL0qoXCY7VZjpUnz6aIj0WCLz3bMN36RVz1Wn24eZQMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/users.vue?macro=true";
import { default as searchBrXOcnXcoHqGgltcuMWg3oX1pQRuzO2QQiGL4i6QdI0Meta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/search.vue?macro=true";
import { default as videosMvUIpI8sq9Rl_45xbqHfO7AF71x3VYX0BbG8PIC1CFGJkMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/videos.vue?macro=true";
import { default as homeG9eFoNqXy2r3brl7pFBx7acm7Bgm6MB9zklQ6BmAiRMMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/home.vue?macro=true";
import { default as paymentknrDvqHMIKHocCSM27fBzDKtpmlI61lzWLypLQGM0FYMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/payment.vue?macro=true";
import { default as indexd0ZRBRxUH_4573zmbmIQfXKYqBZqfuuXN0esfo7nEX3eQMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/youtubers/index.vue?macro=true";
import { default as checkoutKAaFkxkEORAycrctXAHf_0ukY6qNwMh_45qBHBXM34mKoMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/checkout.vue?macro=true";
import { default as learningPLfcNYWUwiL3nIIa0iPntgYe1ELGUtqzC0yhpoYo5aAMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/learning.vue?macro=true";
import { default as desktop_45downloadL6zXgGXgeqUybUVn_Gt26qP2aGm_450GzQNKX2PdhW1r4Meta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/desktop-download.vue?macro=true";
import { default as change_45password_45_91uuid_93Q6ah1Kmnq4HiYFcJGPQ9KFYnkzgS3CP8Ao9VNgoKLD4Meta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/change-password-[uuid].vue?macro=true";
import { default as searchqN4bUHGbdM4czXBTMQ1Nd2U5Rq6QmwKMlMMS7GNTZNIMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/search.vue?macro=true";
import { default as favoritesJyT_ACeP4R5HvNxh_zhAYAsj7YabQkue4nmebFM1CewMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/favorites.vue?macro=true";
import { default as purchasesMVXHQk2R5h3HMXq8HJYNC5gpvQzpEiTy14Z3kv4UO2EMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/purchases.vue?macro=true";
import { default as _91id_930IxOpAVzhTAT9di_45POaoBq1_crsXqu5t_QrwycNwDpgMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/packs/[id].vue?macro=true";
import { default as instruments59Ko94jMeTjY6EYhUJOSbCujMPA4J75wSuN2UTPX5XoMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/instruments.vue?macro=true";
import { default as _91id_936190_45iiiI7VYQ9jQ8d2GQNZRFtMrOejmL17l9NW21X8Meta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/packs/[id].vue?macro=true";
import { default as list6Npg5HolMz6zsg9KB1OnKql5wiBeTTnY3re38HroMKEMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/packs/list.vue?macro=true";
import { default as user_45_91uuid_935_GMjhUI68iVgvBXg2vJQhg6Ngs5ax_QQLWkB0xTIhwMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/verify/user-[uuid].vue?macro=true";
import { default as _91id_939LaBFBz2g5mH0_45oXjeZP5_JyB3NK_7L1ctFRDY3MvcUMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/creators/[id].vue?macro=true";
import { default as payment_45returnQ_45E_Bamrtk88WE_CTExybexYwcjpzmlsRVO_45ro4l8BQMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/payment-return.vue?macro=true";
import { default as email_45_91uuid_93pKvqwv4hfx5gOGvHpqtWyhwuMKPAfmzEQ_87dGqGm3oMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/verify/email-[uuid].vue?macro=true";
import { default as _91id_93hZ7tzaNh_BN2i2kXFT_umHqDwSnW8a2PKCw2EojK7iEMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/youtubers/channels/[id].vue?macro=true";
import { default as _91id_9363cLZVJGKItVJ4wJAXbRkYfChLmQ_45ZZLJVI0cGLikNcMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/creators/[id].vue?macro=true";
import { default as _91id_93yie84esExzxdzFhXXrtnRFvrTsVLhuPd18YZxSkNoNIMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/instruments/[id].vue?macro=true";
import { default as _91id_93xVBdZ9lBceZ7fDzd9Ux2oW1r0_PdMfWQFvzmNcQX8sEMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/instruments/[id].vue?macro=true";
import { default as listfcGt1bHlmrpQBIjnt8RceFHK3Ushqpm7dPzRepNZ_ZsMeta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/instruments/list.vue?macro=true";
import { default as _91tab_93M5oKbUXhRs7UVFDtIplcJ_451RodXtA_45ta7IsJzckfIj8Meta } from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/account-settings/[tab].vue?macro=true";
import { default as component_45stubuhVSOCDq0fIz_45MBp0UTRwe9Pr3Tp2tW6gBrtlNT3FSEMeta } from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.16.2_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@types+node@22.13.17_bett_52cbac6499406c8c77c1f0f776dfca75/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubuhVSOCDq0fIz_45MBp0UTRwe9Pr3Tp2tW6gBrtlNT3FSE } from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.16.2_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@types+node@22.13.17_bett_52cbac6499406c8c77c1f0f776dfca75/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "404___en",
    path: "/404",
    meta: _404qZ_45AMU2uoSibXQLbHxtuaHnjf97boB7aIHnQWRpEHy0Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/404.vue")
  },
  {
    name: "404___fr",
    path: "/fr/404",
    meta: _404qZ_45AMU2uoSibXQLbHxtuaHnjf97boB7aIHnQWRpEHy0Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/404.vue")
  },
  {
    name: "help___en",
    path: "/help",
    meta: helpDi51Wy6WyR73qNNgzbGa2_xONbteFSboUU6dPVyk_45_45kMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/help.vue")
  },
  {
    name: "help___fr",
    path: "/fr/help",
    meta: helpDi51Wy6WyR73qNNgzbGa2_xONbteFSboUU6dPVyk_45_45kMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/help.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexPlB30iT_45EKa6UklGUHjuTXbhtAo1UKEDE_45pbXvTnzeoMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexPlB30iT_45EKa6UklGUHjuTXbhtAo1UKEDE_45pbXvTnzeoMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginLMbKxyL3S6ISbcaPjbwLDdEsrQBXATHxQIcRnt_45skDQMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/login.vue")
  },
  {
    name: "login___fr",
    path: "/fr/login",
    meta: loginLMbKxyL3S6ISbcaPjbwLDdEsrQBXATHxQIcRnt_45skDQMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/login.vue")
  },
  {
    name: "register___en",
    path: "/register",
    meta: register_KfaqY1MGV3NlCEQfwlNnlE_gqtHhp9VplXLykyR6LUMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/register.vue")
  },
  {
    name: "register___fr",
    path: "/fr/register",
    meta: register_KfaqY1MGV3NlCEQfwlNnlE_gqtHhp9VplXLykyR6LUMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/register.vue")
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93Gfcxej8es09s47M_D1ADbXDOGrVLmiPBWDQ1Tvj_PCAMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/[...slug].vue")
  },
  {
    name: "slug___fr",
    path: "/fr/:slug(.*)*",
    meta: _91_46_46_46slug_93Gfcxej8es09s47M_D1ADbXDOGrVLmiPBWDQ1Tvj_PCAMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/[...slug].vue")
  },
  {
    name: "site-about___en",
    path: "/site/about",
    meta: about_45yiRsyW_P4NQ5hC_45Jej1GsrSFzZinawbZ9j19lvSAvYMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/about.vue")
  },
  {
    name: "site-about___fr",
    path: "/fr/site/about",
    meta: about_45yiRsyW_P4NQ5hC_45Jej1GsrSFzZinawbZ9j19lvSAvYMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/about.vue")
  },
  {
    name: "site-contact___en",
    path: "/site/contact",
    meta: contact3f1fkihGlAnbdR0WfNuJ97HQov0yQ3E5Zt_459r59h1g8Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/contact.vue")
  },
  {
    name: "site-contact___fr",
    path: "/fr/site/contact",
    meta: contact3f1fkihGlAnbdR0WfNuJ97HQov0yQ3E5Zt_459r59h1g8Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/contact.vue")
  },
  {
    name: "private___en",
    path: "/private",
    meta: indexi1_45nTtBWOORWno6Cz_5V96z__rEXrdMsdI6d5N1qAqQMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/index.vue")
  },
  {
    name: "private___fr",
    path: "/fr/private",
    meta: indexi1_45nTtBWOORWno6Cz_5V96z__rEXrdMsdI6d5N1qAqQMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/index.vue")
  },
  {
    name: "not-authorized___en",
    path: "/not-authorized",
    meta: not_45authorizedM1uq_45dW_c6_45S6KU1SjNcqGryYz4r6bE85eG8C1DH2EcMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/not-authorized.vue")
  },
  {
    name: "not-authorized___fr",
    path: "/fr/not-authorized",
    meta: not_45authorizedM1uq_45dW_c6_45S6KU1SjNcqGryYz4r6bE85eG8C1DH2EcMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/not-authorized.vue")
  },
  {
    name: "site-shop-cart___en",
    path: "/site/shop/cart",
    meta: carttTXpNu1zDHfeorTzHiiaQYkVJ3bOCPn94YXJCg_HQ0kMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/shop/cart.vue")
  },
  {
    name: "site-shop-cart___fr",
    path: "/fr/site/shop/cart",
    meta: carttTXpNu1zDHfeorTzHiiaQYkVJ3bOCPn94YXJCg_HQ0kMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/shop/cart.vue")
  },
  {
    name: "forgot-password___en",
    path: "/forgot-password",
    meta: forgot_45passwordSD4wf64ZLigBabaLOgBazequymbvT3exzu_45YejdJYOMMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___fr",
    path: "/fr/forgot-password",
    meta: forgot_45passwordSD4wf64ZLigBabaLOgBazequymbvT3exzu_45YejdJYOMMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/forgot-password.vue")
  },
  {
    name: "private-shop-cart___en",
    path: "/private/shop/cart",
    meta: cartrxxyEWBJHB60ZLzmrxK9SYfImdej9P_S3BLBhB96ng4Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/cart.vue")
  },
  {
    name: "private-shop-cart___fr",
    path: "/fr/private/shop/cart",
    meta: cartrxxyEWBJHB60ZLzmrxK9SYfImdej9P_S3BLBhB96ng4Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/cart.vue")
  },
  {
    name: "private-admin-tags___en",
    path: "/private/admin/tags",
    meta: tagsmrcws0EsF68Ofzte5RfIzifUt6eIshJWixeg9hsE5oEMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/tags.vue")
  },
  {
    name: "private-admin-tags___fr",
    path: "/fr/private/admin/tags",
    meta: tagsmrcws0EsF68Ofzte5RfIzifUt6eIshJWixeg9hsE5oEMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/tags.vue")
  },
  {
    name: "private-user-sales___en",
    path: "/private/user/sales",
    meta: sales8aB03YUh4pzlRzP_45K99uoE1INiGXJ3u6Pr955YPTMnQMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/sales.vue")
  },
  {
    name: "private-user-sales___fr",
    path: "/fr/private/user/sales",
    meta: sales8aB03YUh4pzlRzP_45K99uoE1INiGXJ3u6Pr955YPTMnQMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/sales.vue")
  },
  {
    name: "private-admin-packs___en",
    path: "/private/admin/packs",
    meta: packspPlQg6xxM64ucHnAYIz8Q7CsWGrKQlZ_DN_45MYHLmNmIMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/packs.vue")
  },
  {
    name: "private-admin-packs___fr",
    path: "/fr/private/admin/packs",
    meta: packspPlQg6xxM64ucHnAYIz8Q7CsWGrKQlZ_DN_45MYHLmNmIMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/packs.vue")
  },
  {
    name: "private-admin-users___en",
    path: "/private/admin/users",
    meta: users0mL0qoXCY7VZjpUnz6aIj0WCLz3bMN36RVz1Wn24eZQMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/users.vue")
  },
  {
    name: "private-admin-users___fr",
    path: "/fr/private/admin/users",
    meta: users0mL0qoXCY7VZjpUnz6aIj0WCLz3bMN36RVz1Wn24eZQMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/users.vue")
  },
  {
    name: "site-catalog-search___en",
    path: "/site/catalog/search",
    meta: searchBrXOcnXcoHqGgltcuMWg3oX1pQRuzO2QQiGL4i6QdI0Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/search.vue")
  },
  {
    name: "site-catalog-search___fr",
    path: "/fr/site/catalog/search",
    meta: searchBrXOcnXcoHqGgltcuMWg3oX1pQRuzO2QQiGL4i6QdI0Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/search.vue")
  },
  {
    name: "private-admin-videos___en",
    path: "/private/admin/videos",
    meta: videosMvUIpI8sq9Rl_45xbqHfO7AF71x3VYX0BbG8PIC1CFGJkMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/videos.vue")
  },
  {
    name: "private-admin-videos___fr",
    path: "/fr/private/admin/videos",
    meta: videosMvUIpI8sq9Rl_45xbqHfO7AF71x3VYX0BbG8PIC1CFGJkMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/videos.vue")
  },
  {
    name: "private-catalog-home___en",
    path: "/private/catalog/home",
    meta: homeG9eFoNqXy2r3brl7pFBx7acm7Bgm6MB9zklQ6BmAiRMMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/home.vue")
  },
  {
    name: "private-catalog-home___fr",
    path: "/fr/private/catalog/home",
    meta: homeG9eFoNqXy2r3brl7pFBx7acm7Bgm6MB9zklQ6BmAiRMMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/home.vue")
  },
  {
    name: "private-shop-payment___en",
    path: "/private/shop/payment",
    meta: paymentknrDvqHMIKHocCSM27fBzDKtpmlI61lzWLypLQGM0FYMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/payment.vue")
  },
  {
    name: "private-shop-payment___fr",
    path: "/fr/private/shop/payment",
    meta: paymentknrDvqHMIKHocCSM27fBzDKtpmlI61lzWLypLQGM0FYMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/payment.vue")
  },
  {
    name: "site-youtubers___en",
    path: "/site/youtubers",
    meta: indexd0ZRBRxUH_4573zmbmIQfXKYqBZqfuuXN0esfo7nEX3eQMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/youtubers/index.vue")
  },
  {
    name: "site-youtubers___fr",
    path: "/fr/site/youtubers",
    meta: indexd0ZRBRxUH_4573zmbmIQfXKYqBZqfuuXN0esfo7nEX3eQMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/youtubers/index.vue")
  },
  {
    name: "private-shop-checkout___en",
    path: "/private/shop/checkout",
    meta: checkoutKAaFkxkEORAycrctXAHf_0ukY6qNwMh_45qBHBXM34mKoMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/checkout.vue")
  },
  {
    name: "private-shop-checkout___fr",
    path: "/fr/private/shop/checkout",
    meta: checkoutKAaFkxkEORAycrctXAHf_0ukY6qNwMh_45qBHBXM34mKoMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/checkout.vue")
  },
  {
    name: "private-user-learning___en",
    path: "/private/user/learning",
    meta: learningPLfcNYWUwiL3nIIa0iPntgYe1ELGUtqzC0yhpoYo5aAMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/learning.vue")
  },
  {
    name: "private-user-learning___fr",
    path: "/fr/private/user/learning",
    meta: learningPLfcNYWUwiL3nIIa0iPntgYe1ELGUtqzC0yhpoYo5aAMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/learning.vue")
  },
  {
    name: "site-desktop-download___en",
    path: "/site/desktop-download",
    meta: desktop_45downloadL6zXgGXgeqUybUVn_Gt26qP2aGm_450GzQNKX2PdhW1r4Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/desktop-download.vue")
  },
  {
    name: "site-desktop-download___fr",
    path: "/fr/site/desktop-download",
    meta: desktop_45downloadL6zXgGXgeqUybUVn_Gt26qP2aGm_450GzQNKX2PdhW1r4Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/desktop-download.vue")
  },
  {
    name: "change-password-uuid___en",
    path: "/change-password-:uuid()",
    meta: change_45password_45_91uuid_93Q6ah1Kmnq4HiYFcJGPQ9KFYnkzgS3CP8Ao9VNgoKLD4Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/change-password-[uuid].vue")
  },
  {
    name: "change-password-uuid___fr",
    path: "/fr/change-password-:uuid()",
    meta: change_45password_45_91uuid_93Q6ah1Kmnq4HiYFcJGPQ9KFYnkzgS3CP8Ao9VNgoKLD4Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/change-password-[uuid].vue")
  },
  {
    name: "private-catalog-search___en",
    path: "/private/catalog/search",
    meta: searchqN4bUHGbdM4czXBTMQ1Nd2U5Rq6QmwKMlMMS7GNTZNIMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/search.vue")
  },
  {
    name: "private-catalog-search___fr",
    path: "/fr/private/catalog/search",
    meta: searchqN4bUHGbdM4czXBTMQ1Nd2U5Rq6QmwKMlMMS7GNTZNIMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/search.vue")
  },
  {
    name: "private-user-favorites___en",
    path: "/private/user/favorites",
    meta: favoritesJyT_ACeP4R5HvNxh_zhAYAsj7YabQkue4nmebFM1CewMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/favorites.vue")
  },
  {
    name: "private-user-favorites___fr",
    path: "/fr/private/user/favorites",
    meta: favoritesJyT_ACeP4R5HvNxh_zhAYAsj7YabQkue4nmebFM1CewMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/favorites.vue")
  },
  {
    name: "private-user-purchases___en",
    path: "/private/user/purchases",
    meta: purchasesMVXHQk2R5h3HMXq8HJYNC5gpvQzpEiTy14Z3kv4UO2EMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/purchases.vue")
  },
  {
    name: "private-user-purchases___fr",
    path: "/fr/private/user/purchases",
    meta: purchasesMVXHQk2R5h3HMXq8HJYNC5gpvQzpEiTy14Z3kv4UO2EMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/purchases.vue")
  },
  {
    name: "site-catalog-packs-id___en",
    path: "/site/catalog/packs/:id()",
    meta: _91id_930IxOpAVzhTAT9di_45POaoBq1_crsXqu5t_QrwycNwDpgMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/packs/[id].vue")
  },
  {
    name: "site-catalog-packs-id___fr",
    path: "/fr/site/catalog/packs/:id()",
    meta: _91id_930IxOpAVzhTAT9di_45POaoBq1_crsXqu5t_QrwycNwDpgMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/packs/[id].vue")
  },
  {
    name: "private-admin-instruments___en",
    path: "/private/admin/instruments",
    meta: instruments59Ko94jMeTjY6EYhUJOSbCujMPA4J75wSuN2UTPX5XoMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/instruments.vue")
  },
  {
    name: "private-admin-instruments___fr",
    path: "/fr/private/admin/instruments",
    meta: instruments59Ko94jMeTjY6EYhUJOSbCujMPA4J75wSuN2UTPX5XoMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/admin/instruments.vue")
  },
  {
    name: "private-catalog-packs-id___en",
    path: "/private/catalog/packs/:id()",
    meta: _91id_936190_45iiiI7VYQ9jQ8d2GQNZRFtMrOejmL17l9NW21X8Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/packs/[id].vue")
  },
  {
    name: "private-catalog-packs-id___fr",
    path: "/fr/private/catalog/packs/:id()",
    meta: _91id_936190_45iiiI7VYQ9jQ8d2GQNZRFtMrOejmL17l9NW21X8Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/packs/[id].vue")
  },
  {
    name: "private-catalog-packs-list___en",
    path: "/private/catalog/packs/list",
    meta: list6Npg5HolMz6zsg9KB1OnKql5wiBeTTnY3re38HroMKEMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/packs/list.vue")
  },
  {
    name: "private-catalog-packs-list___fr",
    path: "/fr/private/catalog/packs/list",
    meta: list6Npg5HolMz6zsg9KB1OnKql5wiBeTTnY3re38HroMKEMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/packs/list.vue")
  },
  {
    name: "private-verify-user-uuid___en",
    path: "/private/verify/user-:uuid()",
    meta: user_45_91uuid_935_GMjhUI68iVgvBXg2vJQhg6Ngs5ax_QQLWkB0xTIhwMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/verify/user-[uuid].vue")
  },
  {
    name: "private-verify-user-uuid___fr",
    path: "/fr/private/verify/user-:uuid()",
    meta: user_45_91uuid_935_GMjhUI68iVgvBXg2vJQhg6Ngs5ax_QQLWkB0xTIhwMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/verify/user-[uuid].vue")
  },
  {
    name: "site-catalog-creators-id___en",
    path: "/site/catalog/creators/:id()",
    meta: _91id_939LaBFBz2g5mH0_45oXjeZP5_JyB3NK_7L1ctFRDY3MvcUMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/creators/[id].vue")
  },
  {
    name: "site-catalog-creators-id___fr",
    path: "/fr/site/catalog/creators/:id()",
    meta: _91id_939LaBFBz2g5mH0_45oXjeZP5_JyB3NK_7L1ctFRDY3MvcUMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/creators/[id].vue")
  },
  {
    name: "private-shop-payment-return___en",
    path: "/private/shop/payment-return",
    meta: payment_45returnQ_45E_Bamrtk88WE_CTExybexYwcjpzmlsRVO_45ro4l8BQMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/payment-return.vue")
  },
  {
    name: "private-shop-payment-return___fr",
    path: "/fr/private/shop/payment-return",
    meta: payment_45returnQ_45E_Bamrtk88WE_CTExybexYwcjpzmlsRVO_45ro4l8BQMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/shop/payment-return.vue")
  },
  {
    name: "private-verify-email-uuid___en",
    path: "/private/verify/email-:uuid()",
    meta: email_45_91uuid_93pKvqwv4hfx5gOGvHpqtWyhwuMKPAfmzEQ_87dGqGm3oMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/verify/email-[uuid].vue")
  },
  {
    name: "private-verify-email-uuid___fr",
    path: "/fr/private/verify/email-:uuid()",
    meta: email_45_91uuid_93pKvqwv4hfx5gOGvHpqtWyhwuMKPAfmzEQ_87dGqGm3oMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/verify/email-[uuid].vue")
  },
  {
    name: "site-youtubers-channels-id___en",
    path: "/site/youtubers/channels/:id()",
    meta: _91id_93hZ7tzaNh_BN2i2kXFT_umHqDwSnW8a2PKCw2EojK7iEMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/youtubers/channels/[id].vue")
  },
  {
    name: "site-youtubers-channels-id___fr",
    path: "/fr/site/youtubers/channels/:id()",
    meta: _91id_93hZ7tzaNh_BN2i2kXFT_umHqDwSnW8a2PKCw2EojK7iEMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/youtubers/channels/[id].vue")
  },
  {
    name: "private-catalog-creators-id___en",
    path: "/private/catalog/creators/:id()",
    meta: _91id_9363cLZVJGKItVJ4wJAXbRkYfChLmQ_45ZZLJVI0cGLikNcMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/creators/[id].vue")
  },
  {
    name: "private-catalog-creators-id___fr",
    path: "/fr/private/catalog/creators/:id()",
    meta: _91id_9363cLZVJGKItVJ4wJAXbRkYfChLmQ_45ZZLJVI0cGLikNcMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/creators/[id].vue")
  },
  {
    name: "site-catalog-instruments-id___en",
    path: "/site/catalog/instruments/:id()",
    meta: _91id_93yie84esExzxdzFhXXrtnRFvrTsVLhuPd18YZxSkNoNIMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/instruments/[id].vue")
  },
  {
    name: "site-catalog-instruments-id___fr",
    path: "/fr/site/catalog/instruments/:id()",
    meta: _91id_93yie84esExzxdzFhXXrtnRFvrTsVLhuPd18YZxSkNoNIMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/site/catalog/instruments/[id].vue")
  },
  {
    name: "private-catalog-instruments-id___en",
    path: "/private/catalog/instruments/:id()",
    meta: _91id_93xVBdZ9lBceZ7fDzd9Ux2oW1r0_PdMfWQFvzmNcQX8sEMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/instruments/[id].vue")
  },
  {
    name: "private-catalog-instruments-id___fr",
    path: "/fr/private/catalog/instruments/:id()",
    meta: _91id_93xVBdZ9lBceZ7fDzd9Ux2oW1r0_PdMfWQFvzmNcQX8sEMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/instruments/[id].vue")
  },
  {
    name: "private-catalog-instruments-list___en",
    path: "/private/catalog/instruments/list",
    meta: listfcGt1bHlmrpQBIjnt8RceFHK3Ushqpm7dPzRepNZ_ZsMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/instruments/list.vue")
  },
  {
    name: "private-catalog-instruments-list___fr",
    path: "/fr/private/catalog/instruments/list",
    meta: listfcGt1bHlmrpQBIjnt8RceFHK3Ushqpm7dPzRepNZ_ZsMeta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/catalog/instruments/list.vue")
  },
  {
    name: "private-user-account-settings-tab___en",
    path: "/private/user/account-settings/:tab()",
    meta: _91tab_93M5oKbUXhRs7UVFDtIplcJ_451RodXtA_45ta7IsJzckfIj8Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/account-settings/[tab].vue")
  },
  {
    name: "private-user-account-settings-tab___fr",
    path: "/fr/private/user/account-settings/:tab()",
    meta: _91tab_93M5oKbUXhRs7UVFDtIplcJ_451RodXtA_45ta7IsJzckfIj8Meta || {},
    component: () => import("/home/runner/work/divine/divine/apps/dvn-nuxt-platform/pages/private/user/account-settings/[tab].vue")
  },
  {
    name: component_45stubuhVSOCDq0fIz_45MBp0UTRwe9Pr3Tp2tW6gBrtlNT3FSEMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubuhVSOCDq0fIz_45MBp0UTRwe9Pr3Tp2tW6gBrtlNT3FSE
  }
]