import payload_plugin_l0I11Bt9_F06hAe1qG6_nrqSsvHyaGwuipYRvRr3oPA from "/home/runner/work/divine/divine/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_r8KxZ7FFRlRGtLnvvlEKZ6gxinDEarTwCyyhytRt3Z4 from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.16.2_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@types+node@22.13.17_bett_52cbac6499406c8c77c1f0f776dfca75/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LsaSHYuhq8GqsJrBbRCDn7f2tr_hBBwYbK7JytO5t3w from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.16.2_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@types+node@22.13.17_bett_52cbac6499406c8c77c1f0f776dfca75/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_D6bixHFoEU4OGoO_XbpQB4Fg3nL_nYBMLovpWtiiqAc from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.16.2_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@types+node@22.13.17_bett_52cbac6499406c8c77c1f0f776dfca75/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_5_Df5MibenU9q7hOWAlwbNrMpfjqEZ03E35FowsCVs8 from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_RWMdu7CZPIpWWtATvbFOWfLoUXqIKfXe7pR2fVLDfYg from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.16.2_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@types+node@22.13.17_bett_52cbac6499406c8c77c1f0f776dfca75/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_vkyBVpobnTY7isJQn2n6tC2hwFJ59aXUg_yiVonF4gc from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.16.2_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@types+node@22.13.17_bett_52cbac6499406c8c77c1f0f776dfca75/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_mwUd4zJG7DHDkakSfasr59PTbFgYlbOpwhkvMhUUwos from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.16.2_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@types+node@22.13.17_bett_52cbac6499406c8c77c1f0f776dfca75/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Ja_M3Yc0mKmyE_dWVc10_lwI60gHEIA7UdS3eN3CJYw from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.16.2_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@types+node@22.13.17_bett_52cbac6499406c8c77c1f0f776dfca75/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_fsWORruKmrcZLdOj8agn4VF2t_I0tKoWiPgN2cjwMz8 from "/home/runner/work/divine/divine/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/.nuxt/components.plugin.mjs";
import prefetch_client_pl4kaCJGNiix7FM7OkMZ2SPScBL9ElZwAtlmsMwUtCk from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.16.2_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@types+node@22.13.17_bett_52cbac6499406c8c77c1f0f776dfca75/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_IMK19MXVVDOOYneeaLZhQh5WOyg3IZPBsZgGxThAlic from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import i18n_vpNs8i0cr9N1X_z0gnT94vc4uwUE_tOHJq1wXR6Srws from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import switch_locale_path_ssr_j00NcZdFS5Ie9cbiJTWJGMCmN84BucS4w_enQHwDGn8 from "/home/runner/work/divine/divine/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_a17e21bd26f2b564b2f18fe971e4489d/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_O_Vn7tG21_ptwloESdQHlZJ7hNV8q317TFOy2fuYo6U from "/home/runner/work/divine/divine/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_a17e21bd26f2b564b2f18fe971e4489d/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_ZeBT7qL7ZRfQBWv9tlDu_NovTxoHHCZALZKIPs6oZZo from "/home/runner/work/divine/divine/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_a17e21bd26f2b564b2f18fe971e4489d/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import index_C8E19tJVqz3SYHIzKNMhnt6kvLJaUm25pQUgVrtlSK4 from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/layouts/index.ts";
import index_Mo1S6_8DJFfUU6Eb40yQS0JPcbw_SGBX3UVf4gR_fFc from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/vuetify/index.ts";
import casl_Ja0SIwPUi0Eb9wkr321OmH1XygsrJdFSYcrmu41cIGg from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/casl.ts";
import electron_ipc_api_gbCGjJAjAU5rQCLj0yuCxhwJ7IiFTXNXrt8J_48nVQs from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/electron-ipc-api.ts";
import nuxt_error_hooks_GAsIJPs2jM52CIJfguUf2GmqBhQ78FnuZFCQQvt6XRQ from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/nuxt-error-hooks.ts";
import nuxt_page_hooks_sD8B87TEPC88yX3xlV532IUS_GgMCTbV7SDr6VXvOyg from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/nuxt-page-hooks.ts";
import perfect_scrollbar__MYQ7EyAhHOLCXMQ_6YQn0AI82pBvwahXXrD1kWwXAk from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/perfect-scrollbar.ts";
import trpc_client_S0hkmJ1Xo_4xeZ6MhT5fQIyljS9dssSqhTtmJ_rIql8 from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/trpc-client.ts";
import ssg_detect_HgCbFH0u2MdpD73hnGZWvXTRuI1GsmYQFVJ62WBZ6wk from "/home/runner/work/divine/divine/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_a17e21bd26f2b564b2f18fe971e4489d/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_l0I11Bt9_F06hAe1qG6_nrqSsvHyaGwuipYRvRr3oPA,
  revive_payload_client_r8KxZ7FFRlRGtLnvvlEKZ6gxinDEarTwCyyhytRt3Z4,
  unhead_LsaSHYuhq8GqsJrBbRCDn7f2tr_hBBwYbK7JytO5t3w,
  router_D6bixHFoEU4OGoO_XbpQB4Fg3nL_nYBMLovpWtiiqAc,
  _0_siteConfig_5_Df5MibenU9q7hOWAlwbNrMpfjqEZ03E35FowsCVs8,
  payload_client_RWMdu7CZPIpWWtATvbFOWfLoUXqIKfXe7pR2fVLDfYg,
  navigation_repaint_client_vkyBVpobnTY7isJQn2n6tC2hwFJ59aXUg_yiVonF4gc,
  check_outdated_build_client_mwUd4zJG7DHDkakSfasr59PTbFgYlbOpwhkvMhUUwos,
  chunk_reload_client_Ja_M3Yc0mKmyE_dWVc10_lwI60gHEIA7UdS3eN3CJYw,
  plugin_vue3_fsWORruKmrcZLdOj8agn4VF2t_I0tKoWiPgN2cjwMz8,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_pl4kaCJGNiix7FM7OkMZ2SPScBL9ElZwAtlmsMwUtCk,
  plugin_client_IMK19MXVVDOOYneeaLZhQh5WOyg3IZPBsZgGxThAlic,
  i18n_vpNs8i0cr9N1X_z0gnT94vc4uwUE_tOHJq1wXR6Srws,
  switch_locale_path_ssr_j00NcZdFS5Ie9cbiJTWJGMCmN84BucS4w_enQHwDGn8,
  route_locale_detect_O_Vn7tG21_ptwloESdQHlZJ7hNV8q317TFOy2fuYo6U,
  i18n_ZeBT7qL7ZRfQBWv9tlDu_NovTxoHHCZALZKIPs6oZZo,
  index_C8E19tJVqz3SYHIzKNMhnt6kvLJaUm25pQUgVrtlSK4,
  index_Mo1S6_8DJFfUU6Eb40yQS0JPcbw_SGBX3UVf4gR_fFc,
  casl_Ja0SIwPUi0Eb9wkr321OmH1XygsrJdFSYcrmu41cIGg,
  electron_ipc_api_gbCGjJAjAU5rQCLj0yuCxhwJ7IiFTXNXrt8J_48nVQs,
  nuxt_error_hooks_GAsIJPs2jM52CIJfguUf2GmqBhQ78FnuZFCQQvt6XRQ,
  nuxt_page_hooks_sD8B87TEPC88yX3xlV532IUS_GgMCTbV7SDr6VXvOyg,
  perfect_scrollbar__MYQ7EyAhHOLCXMQ_6YQn0AI82pBvwahXXrD1kWwXAk,
  trpc_client_S0hkmJ1Xo_4xeZ6MhT5fQIyljS9dssSqhTtmJ_rIql8,
  ssg_detect_HgCbFH0u2MdpD73hnGZWvXTRuI1GsmYQFVJ62WBZ6wk
]