import { ref, defineStore } from '#imports'

export const useSnackbar = defineStore('snackbar', () => {
  // deault values
  const TIMEOUT = 5000
  const ICON = 'mdi-information'
  const MESSAGE = ''

  // state
  const isVisible = ref(false)
  const timeout = ref(TIMEOUT)
  const icon = ref(ICON)
  const message = ref(MESSAGE)

  function reset() {
    isVisible.value = false
    timeout.value = TIMEOUT
    icon.value = ICON
    message.value = MESSAGE
  }

  function show(_icon = ICON, _message = MESSAGE) {
    reset()
    isVisible.value = true
    icon.value = _icon
    message.value = _message
  }

  return {
    isVisible,
    timeout,
    icon,
    message,

    // functions
    reset,
    show,
  }
})
